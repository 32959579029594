<script>
import { CloseCircle, TrashIcon } from "@/icons";
import AddOrdersToCurrentInvoice from "@/views/payouts/components/AddOrdersToCurrentInvoice.vue";
import AnimatedNumber from "animated-number-vue";
import InvoicedOrdersTable from "@/views/payouts/components/InvoicedOrdersTable.vue";
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";

export default {
  name: "CreatePayoutDialog",

  mixins: [payoutsMixin],

  data() {
    return {
      isPayoutStatusOptionsVisible: false,
      selectedPayoutStatus: "unpaid",
      rules: [
        value => /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*$/.test(value) || 'Amount required'
      ],
      requiredItem: [(v) => !!v || "Item is required"],
      requiredDescription: [(v) => !!v || "Description is required"],
      valid: false
    }
  },

  components: {
    AddOrdersToCurrentInvoice,
    CloseCircle,
    InvoicedOrdersTable,
    TrashIcon,
    AnimatedNumber,
  },

  watch: {
    'isPayoutDialogVisible'(val) {
      setTimeout(() => {
        this.getFinalPayoutValue()
      }, 500)
    }
  },

  computed: {
    isPreviewMode() {
      return this.preview.status.includes("unpaid") && this.preview.status.length === 1;
    },
  },

  methods: {
    async createPayoutHandler(markAsPaid) {
      let isFormValid = this.$refs.lineItemsForm.validate()
      if(!isFormValid) { return }

      const { payoutIds, sales, taxes, total } = this.payoutsDashboard;
      const { lineItems } = this;

      await this.$store.dispatch("payouts/createPayout", {
        commission_record_ids: payoutIds,
        current_store_id: +this.currentShop.id,
        payout_total: this.payoutTotal ?? total,
        sale_total: +sales,
        source_store_id: +this.payableOrdersFilters.target_store,
        tax_total: +taxes,
        mark_as_paid: markAsPaid,
        payout_line_items: lineItems
      });

      this.isPayoutDialogVisible = false;
      this.payoutsDashboard = { commission: 0, payoutIds: [], taxes: 0, total: 0 };
      this.payoutsDashboard.payoutIds = [];
      this.payoutsPreview = null;
      this.selectedPayoutOrders = [];

      await this.fetchPayouts(+this.payableOrdersFilters.target_store);
      this.lineItems = []
      if(markAsPaid) {
        this.paidPayoutsFilters = { target_store: "All Stores", orders_status: "paid", date_range: "Last 30 days" }
        this.$router.push({ name: 'PaidPayouts' })
      } else {
        this.$router.push({ name: 'UnpaidPayouts' })
        this.payoutsFilters = { target_store: "All Stores", orders_status: "unpaid", date_range: "Last 30 days" }
      }
    },

    showAddOrdersHandler() {
      this.isAddOrdersToInvoiceVisible = true;
    },

    onClickOutsidePayoutStatusOptions() {
      this.isPayoutStatusOptionsVisible = false;
      this.setPayoutStatus();
    },

    addLineItemHandler() {
      this.lineItems.push({
        name: '',
        description: '',
        amount: '',
      })
    },

    showInput(event, lineItem) {
      lineItem.showInput = true;
      event.currentTarget.previousElementSibling.focus()
    },

    deleteLineItemHandler(index, lineItem) {
      const LINE_ITEM = this.lineItems[index]
      const INDEX = this.lineItems.indexOf(LINE_ITEM)
      this.lineItems.splice(INDEX, 1)
      this.getFinalPayoutValue()
    },

    formatToPrice(value) {
      return `${value.toFixed(2)}`;
    },
  }
}
</script>

<template>
  <v-dialog v-model="isPayoutDialogVisible" transition="slide-x-reverse-transition" light content-class="offscreen-dialog">
    <v-card elevation="0" class="payouts">
      <!-- Details -->
      <transition name="slide-x-reverse-transition" mode="out-in">
        <div v-if="!isAddOrdersToInvoiceVisible" key="1">
          <aside class="payouts-container payouts__details">
            <h2 class="payouts__heading d-flex flex-space-between">
              Payout
              <div>
                <span class="close-modal-icon" @click="isPayoutDialogVisible = false">
                  <CloseCircle />
                </span>
              </div>
            </h2>

            <div class="payouts__breakdown" style="display: block; margin-right: 175px;">
              <div class="mt-6 payouts__breakdown-details first">
                <span class="title">Date</span>
                <span class="title-data">{{ new Date().toLocaleString("en-US", { timeZone: "Australia/Sydney" }) | moment("DD MMMM, YYYY") }}</span>
              </div>

              <div class="payouts__breakdown-details">
                <span class="title">Send to</span>
                <span class="title-data">{{ selectedStore.text }}</span>
              </div>

              <div class="payouts__breakdown-details second-last">
                <span class="title">Amount</span>
                <v-form v-model="valid" class="title-data" ref="lineItemsForm">
                  <table cellspacing="0">
                    <thead>
                      <tr>
                        <th style="width: 35%;">Item</th>
                        <th style="width: 42.5%;">Description</th>
                        <th style="width: 22.5%;" class="text-right">Amount($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Sales value</td>
                        <td>Subtotal</td>
                        <td class="text-right amountParameter tabular-fonts">{{ payoutsDashboard.sales | fixedDecimal }}</td>
                      </tr>
                      <tr>
                        <td>Commission</td>
                        <td>
                          <a href="javascript:void(0);" @click="scrollToOrdersSection" class="link link-underline">See orders invoiced</a>
                        </td>
                        <td class="text-right amountParameter tabular-fonts">-{{ payoutsDashboard.commission | fixedDecimal }}</td>
                      </tr>
                      <tr v-for="(lineItem, index) in lineItems" :key="index" class="line-items">
                        <td style="vertical-align: top;">
                          <v-text-field  :rules="requiredItem" v-model="lineItem.name" solo light dense placeholder="Enter item name" style="margin-left: -8px;" />
                        </td>
                        <td style="vertical-align: top;">
                          <v-text-field :rules="requiredDescription" v-model="lineItem.description" solo light dense placeholder="Enter description" />
                        </td>
                        <td class="text-right line-item-value" style="position: relative; vertical-align: top;">
                          <v-text-field :rules="rules" placeholder="0.00" solo light dense v-model="lineItem.amount" class="price-input" @blur="getFinalPayoutValue()" style="transform: translateX(9px);" />
                        </td>
                        <td class="clear-line-item" @click="deleteLineItemHandler(index, null)">
                          <TrashIcon />
                        </td>
                      </tr>
                      <tr class="add-line-item">
                        <td class="v2">
                          <a href="javascript:void(0);" class="link link-underline" @click="addLineItemHandler" :class="{ 'disabled': !valid }">Add another line item</a>
                        </td>
                        <td colspan="2">e.g. taxes, shipping, deductions etc</td>
                      </tr>
                      <tr class="amount-due">
                        <td>Amount due</td>
                        <td colspan="2" class="text-right tabular-fonts">
                          <animated-number
                            :duration="350"
                            :formatValue="formatToPrice"
                            :value="(Number(payoutTotal) || payoutsDashboard.total)"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-form>
              </div>

              <div class="payouts__breakdown-details last mt-8">
                <span class="title">To mark as paid later, use <br> <span>Manage Payout</span> in the <span>Unpaid</span> tab</span>
                <span class="title-data text-right">
                  <button class="btn btn-border lg" @click="createPayoutHandler(false)" :disabled="selectedPayoutOrders.length === 0">Create</button>
                  <button class="btn btn-border btn-success lg ml-3" @click="createPayoutHandler(true)" :disabled="selectedPayoutOrders.length === 0">Create and mark as paid</button>
                </span>
              </div>

            </div>
          </aside>

          <!-- Invoiced Orders -->
          <aside class="payouts-container payouts__orders" data-section="invoiced-orders">
            <h2 class="payouts__heading">
              Orders Invoiced
              <button class="btn btn-border inverse lg" v-if="isPreviewMode" @click="showAddOrdersHandler">Add Orders</button>
            </h2>
            <p class="text-secondary mt-1 mb-4">
              <span v-if="selectedPayoutOrders.length > 0">{{ payoutsDashboard.orders_count }} {{ payoutsDashboard.orders_count > 1 ? "products" : "product" }} from {{ selectedPayoutOrders.length }} {{ selectedPayoutOrders.length > 1 ? "orders" : "Order" }}</span>
            </p>
            <InvoicedOrdersTable :isPreviewMode="isPreviewMode" />
          </aside>
        </div>

        <div v-else key="2">
          <AddOrdersToCurrentInvoice />
        </div>
      </transition>
    </v-card>
  </v-dialog>
</template>
