<script>
import { ArrowBack, CalendarIcon, ChevronDown, CloseCircle, SearchIcon, StoreLarge } from "@/icons";
import { LOAD_CURRENT_STORE_INVENTORIES } from "@/store/actions.type";
import { mapState } from "vuex";
import CreatePayoutDialog from "@/views/payouts/components/CreatePayoutDialog.vue";
import DateRangeFilter from "@/views/payouts/components/DateRangeFilter.vue";
import OrderDetailsBlock from "@/views/order/components/OrderDetailsBlock";
import PageHeader from "@/views/payouts/components/PageHeader.vue";
import PayableOrderTable from "@/views/payouts/components/PayableOrderTable.vue";
import PayoutsTable from "@/views/payouts/components/PayoutsTable.vue";
import SelectFilter from "@/views/payouts/components/SelectFilter.vue";
import Summary from "@/views/payouts/components/Summary.vue";
import Tabs from "@/views/payouts/components/Tabs.vue";
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";

export default {
  name: "Payouts",

  mixins: [payoutsMixin],

  data() {
    return {
      isOrderFilterVisible: false,
      isStoreFilterVisible: false,
      filteredStoreOptions: [],
      isPayoutStatusOptionsVisible: false,
      selectedPayoutStatus: "unpaid",
      storeSearchTerm: "",
    }
  },

  async created() {
    try {
      this.loadingPayouts = true;
      if(!this.currentShop) {
        await this.$store.dispatch(`shop/init`).then(() => {
          if (this.currentShop != null) {
            this.$store.dispatch(`shop/${LOAD_CURRENT_STORE_INVENTORIES}`);
          }
        });
      }

      this.filteredStoreOptions = [...this.filteredStoreOptions, ...this.storeFilterOptions];
      this.filteredStoreOptions = this.arrayToObject(this.filteredStoreOptions, "value");
      if(this.payableOrdersFilters.date_range === 'Last 30 days') { this.setDefaultStartAndEndDate(); }
      this.$store.dispatch("payouts/fetchDashboardStats", this.currentShop.id);
      if(this.isViewingPayableOrders) {
        await this.fetchPayableOrders();
      } else {
        await this.fetchPayouts(this.payableOrdersFilters.target_store);
        this.payoutsCurrentPage = 1;
      }
      this.loadingPayouts = false;
    } catch(error) {
      this.loadingPayouts = false;
    }
  },

  computed: {
    ...mapState("shop", ["connectedShops"]),
    ...mapState('plan', ['currentActivePlan']),

    storeFilterOptions() {
      return this.connectedShops.map(shop => {
        return {
          key: "target_store",
          text: shop.store_domain,
          value: shop.id,
          commission: shop.store_commission
        }
      });
    },

    showPayoutsSummary() {
      return !this.isViewingPayableOrders && this.payoutsDashboard?.payoutIds.length > 0;
    },
  },

  components: {
    ArrowBack,
    CalendarIcon,
    ChevronDown,
    CloseCircle,
    CreatePayoutDialog,
    DateRangeFilter,
    OrderDetailsBlock,
    PageHeader,
    PayableOrderTable,
    PayoutsTable,
    SearchIcon,
    SelectFilter,
    StoreLarge,
    Summary,
    Tabs,
  },

  methods: {
    closeFilters() {
      this.isOrderFilterVisible = false;
      this.isStoreFilterVisible = false;
    },

    async applyStoreFilter(store) {
      const { key, value } = store;
      await this.applyFilter({ key, value });
      this.selectedStore = store;
      this.payoutsCurrentPage = 1;
    },

    async applyFilter({ key, value }) {
      this.payableOrdersFilters[key] = value;
      this.closeFilters();

      if(this.isViewingPayableOrders) {
        await this.fetchPayableOrders();
      } else {
        await this.fetchPayouts(this.payableOrdersFilters.target_store);
        this.payoutsDashboard.payoutIds = [];
        this.selectedPayoutOrders = [];
        this.payoutsDashboard = { commission: 0, payoutIds: [], taxes: 0, total: 0 };
        this.payoutsCurrentPage = 1;
      }
    },

    onClickOutsideStoreFilter() {
      this.isStoreFilterVisible = false;
    },

    onClickOutsideOrderFilter() {
      this.isOrderFilterVisible = false;
    },

    async onClearFiltersHandler() {
      this.payableOrdersFilters.orders_status = "unpaid";
      this.searchTerm = "";
      this.setDefaultStartAndEndDate();
      this.payableOrdersFilters.date_range = "Last 30 days";
      if(this.isViewingPayableOrders) {
        this.payableOrdersFilters.target_store = "All Stores";
        this.selectedStore = { text: "All Stores" };
        await this.fetchPayableOrders();
      } else {
        await this.fetchPayouts(this.payableOrdersFilters.target_store);
        this.payoutsCurrentPage = 1;
      }
    },

    async onDateRangeSelectedHandler(value) {
      const { label } = value;
      this.payableOrdersFilters.date_range = label;
      this.dateRange.startDate = value.startDate;
      this.dateRange.endDate = value.endDate;
      if(this.isViewingPayableOrders) {
        await this.fetchPayableOrders();
      } else {
        await this.fetchPayouts(this.payableOrdersFilters.target_store);
        this.payoutsCurrentPage = 1;
      }
    },

    async backToPayableOrdersHandler() {
      this.isViewingPayableOrders = true;
      this.selectedPayoutOrders = [];
      this.payableOrdersFilters.target_store = "All Stores";
      this.selectedStore = { text: "All Stores" };
      await this.fetchPayableOrders();
      this.searchTerm = "";
      this.payoutsDashboard = {
        commission: 0,
        payoutIds: [],
        total: 0
      };
      this.payoutsCurrentPage = 1;
    },

    async searchOrders() {
      await this.fetchPayouts(this.payableOrdersFilters.target_store);
      this.payoutsDashboard.payoutIds = [];
      this.selectedPayoutOrders = [];
      this.payoutsDashboard = { commission: 0, payoutIds: [], taxes: 0, total: 0 };
      this.payoutsCurrentPage = 1;
    },

    swapItems() {
      this.isSearchingStore = true;
      document.querySelector(".search-input").focus();
    },

    onSearchStoreHandler(event) {
      this.filteredStoreOptions = this.arrayToObject(this.storeFilterOptions.filter(store => store.text.includes(event.target.value)), "value");
    },

    createBulkPayoutHandler() {
      if(this.payoutsDashboard.payoutIds.length > 0) {
        this.isPayoutDialogVisible = true;
      }
    },
  }
}
</script>

<template>
  <section class="page payouts-page">
    <PayoutsUpgradeDialog />

    <!-- Page Header -->
    <PageHeader pageDescription="Manage Payouts for fulfilled orders">
      <template #title>Manage payouts</template>
    </PageHeader>

    <!-- Summary -->
    <Summary :summary="payoutsDashboard" />

    <!-- Quick Actions -->
    <transition name="slide-x-reverse-transition">
      <section class="payout-options" v-if="showPayoutsSummary">
        <aside class="payout-options__right">
          <button class="btn btn-border inverse d-flex align-center justify-center" @click="createBulkPayoutHandler" :disabled="loadindDashboardStats">
            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
              <path d="M11.5 0.556641C5.29 0.556641 0.25 5.59664 0.25 11.8066C0.25 18.0166 5.29 23.0566 11.5 23.0566C17.71 23.0566 22.75 18.0166 22.75 11.8066C22.75 5.59664 17.71 0.556641 11.5 0.556641ZM11.5 20.8066C6.53875 20.8066 2.5 16.7679 2.5 11.8066C2.5 6.84539 6.53875 2.80664 11.5 2.80664C16.4613 2.80664 20.5 6.84539 20.5 11.8066C20.5 16.7679 16.4613 20.8066 11.5 20.8066ZM11.8488 10.8391C9.8575 10.3329 9.21625 9.78164 9.21625 8.96039C9.21625 8.01539 10.105 7.35164 11.5787 7.35164C13.1312 7.35164 13.7163 8.09414 13.7613 9.19664H15.685C15.6287 7.68914 14.7062 6.30539 12.8837 5.85539V3.93164H10.2625V5.83289C8.56375 6.19289 7.2025 7.29539 7.2025 8.99414C7.2025 11.0079 8.87875 12.0204 11.32 12.6054C13.5138 13.1229 13.9525 13.8991 13.9525 14.7091C13.9525 15.3054 13.5138 16.2729 11.59 16.2729C9.79 16.2729 9.08125 15.4629 8.98 14.4279H7.045C7.1575 16.3404 8.575 17.4204 10.2625 17.7691V19.6816H12.895V17.8029C14.605 17.4766 15.955 16.4979 15.9663 14.6866C15.955 12.2116 13.8288 11.3566 11.8488 10.8391Z" fill="white"/>
            </svg>
            Create Payout
          </button>
        </aside>
      </section>
    </transition>

    <!-- Tabs -->
    <Tabs />

    <!-- Filters -->
    <section class="filters-listing">
      <ul class="d-flex pa-0 justify-start">
        <li v-if="isViewingPayableOrders" class="filters-listing__filter" data-filter="stores" @click.capture="isStoreFilterVisible = true" v-click-outside="onClickOutsideStoreFilter">
          <SelectFilter>
            <template #iconBefore>
              <StoreLarge />
            </template>
            <template #label>
              <div class="label" @click="swapItems">
                <input type="text" v-model="storeSearchTerm" v-show="isStoreFilterVisible" class="search-input" @keyup="onSearchStoreHandler">
                <span v-show="!isStoreFilterVisible">{{ selectedStore.text }}</span>
              </div>
            </template>
            <template #iconAfter>
              <div class="icon-right absolute">
                <ChevronDown />
              </div>
            </template>
            <template #options>
              <transition name="slide-x-reverse-transition">
                <ul v-if="isStoreFilterVisible" class="filter-options on-left">
                  <li
                    :class="{ 'active': +payableOrdersFilters.target_store === filteredStoreOptions[key].value }"
                    :key="filteredStoreOptions[key].value"
                    @click.stop="applyStoreFilter(value)"
                    v-for="(value, key) in filteredStoreOptions"
                    v-show="Object.keys(filteredStoreOptions).length > 0">
                      {{ filteredStoreOptions[key].text }}
                  </li>
                  <li class="not-found" key="store-not-found" v-if="Object.keys(filteredStoreOptions).length === 0">Store not found</li>
                </ul>
              </transition>
            </template>
          </SelectFilter>
        </li>

        <li class="filters-listing__filter" :class="{ 'd-flex': !isViewingPayableOrders, 'd-none': isViewingPayableOrders }" data-filter="search">
          <div class="input-prepend d-flex" @click="searchOrders">
            <SearchIcon />
          </div>
          <input type="text" v-model="searchTerm" class="input-field prepend-icon" placeholder="Order Number (Press Enter to Search)" @keyup.enter="searchOrders" />
        </li>

        <li class="filters-listing__filter" data-filter="calendar">
          <SelectFilter>
            <template #iconBefore>
              <CalendarIcon />
            </template>
            <template #label>
              <div class="label">{{ payableOrdersFilters.date_range || "Select Range"  }}</div>
            </template>
            <template #iconAfter>
              <div class="icon-right absolute">
                <ChevronDown />
              </div>
            </template>
            <DateRangeFilter opens="right" @onDateRangeSelected="onDateRangeSelectedHandler" :startDate="String(dateRange.startDate)" :endDate="String(dateRange.endDate)" />
          </SelectFilter>
        </li>

        <li class="right-action-container">
          <button class="btn-icon d-flex align-center clear-filters-btn" @click="onClearFiltersHandler">
            <CloseCircle /> <span class="btn-icon--text">Clear Filters</span>
          </button>
        </li>
      </ul>
    </section>

    <!-- Applied Filters -->
    <section v-if="!isViewingPayableOrders" class="applied-filters-row d-flex align-center">
      <aside class="d-flex align-center left-action">
        <div class="left-action-container">
          <button class="btn-icon d-flex align-center go-back-button" @click="backToPayableOrdersHandler()" :disabled="isViewingPayableOrders">
            <ArrowBack /> <span class="btn-icon--text">Stores</span>
          </button>
        </div>
        <div class="applied-filters-listing">
          Payable orders for <strong>{{ selectedStore.text }}</strong>
        </div>
      </aside>
    </section>

    <!-- Tables -->
    <transition name="slide-x-reverse-transition" mode="out-in" duration="150">
      <PayableOrderTable :payableOrdersData="payableOrders" v-if="isViewingPayableOrders" />
      <PayoutsTable :payoutsData="payouts" v-else />
    </transition>

    <!-- Create Payout Dialog -->
    <CreatePayoutDialog />

    <!-- Order Details -->
    <v-dialog v-model="isOrderDetailsVisible" transition="slide-x-reverse-transition" light content-class="offscreen-dialog">
      <OrderDetailsBlock />
    </v-dialog>

  </section>
</template>
