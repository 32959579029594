<script>
import { IconNewTab, OrdersBtn, Store } from "@/icons";
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";

export default {
  name: "PayableOrderTable",

  props: {
    payableOrdersData: {
      type: Object,
      required: true
    }
  },

  mixins: [payoutsMixin],

  computed: {
    payableOrdersLength() {
      return Object.keys(this.payableOrdersData).length;
    }
  },

  components: {
    IconNewTab,
    OrdersBtn,
    Store
  },
}
</script>

<template>
  <section class="table">
    <v-simple-table>
      <template>
        <thead>
          <tr>
            <th style="width: 25%;"><Store /> Source Store</th>
            <th style="width: 15%;">Total Commissions</th>
            <th style="width: 15%;">Total Sales</th>
            <th style="width: 15%;">Unpaid Orders</th>
            <th style="width: 15%;">Payout Amount</th>
            <th style="width: 15%; text-align: right;">Actions</th>
          </tr>
        </thead>
        <tbody v-if="payableOrdersLength === 0 && !loadingPayableOrders && payableOrdersFilters.orders_status === 'unpaid'">
          <tr class="no-hover">
            <td colspan="6" class="text-center empty-table-message">
              <p><strong>You have no payouts to review at this time.</strong></p>
              <p>Payable orders will show when a pushed order has been fulfilled by the source store.</p>
              <p>
                To create a payout, you will need to set up commission for your store.
                Go to <router-link to="/settings/payout-settings" class="link link-underline">Commission Settings</router-link> to get started.
              </p>
              <p class="mb-0 v2 pb-0">
                <a href="https://help.syncio.co/en/articles/6398970-payouts-add-on-destination-store-side" target="_blank" class="link link-underline">
                  Learn more about payouts
                  <IconNewTab />
                </a>
              </p>
            </td>
          </tr>
        </tbody>
        <tbody v-if="payableOrdersLength === 0 && !loadingPayableOrders && payableOrdersFilters.orders_status !== 'unpaid'">
          <tr class="no-hover">
            <td colspan="6" class="text-center empty-table-message">
              <p><strong>There are no payouts to display for the filters selected.</strong></p>
              <p>Please reset your filters or choose a different set.</p>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="!loadingPayableOrders">
          <tr v-for="(order, objKey) in payableOrdersData" :key="order.id">
            <td>{{ order.store_name }}</td>
            <td class="text-success">
              {{ order.total_commissions | currencyFormatter(currency) }}
            </td>
            <td><span v-if="order.total_sales < 0">-$</span>{{ order.total_sales | currencyFormatter(currency) }}</td>
            <td>{{ order.unpaid_orders }}</td>
            <td>
              <strong>{{ order.payout_amount | currencyFormatter(currency) }}</strong>
            </td>
            <td style="text-align: right;">
              <v-btn elevation="0" class="btn btn-border" @click="fetchPayouts(objKey, order)">
                <OrdersBtn /> <span class="ml-3">View details</span>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </section>
</template>
