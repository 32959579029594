<script>
import { ArrowBack, DollarIconLarge } from "@/icons";
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";
import PayoutsTable from "@/views/payouts/components/PayoutsTable.vue";
import Summary from "@/views/payouts/components/Summary.vue";

export default {
  name: "AddOrdersToCurrentInvoice",

  mixins: [payoutsMixin],

  components: {
    ArrowBack,
    DollarIconLarge,
    PayoutsTable,
    Summary,
  },

  computed: {
    filteredPayouts() {
      return Object.values(this.payouts).filter(payout => payout.status === 'unpaid');
    },
  },

  methods: {
    hideAddOrdersHandler() {
      this.isAddOrdersToInvoiceVisible = false;
    },
  }
}
</script>

<template>
  <aside class="payouts-container payouts__details">
    <h2 class="payouts__heading mb-5">
      <span class="flex-space-between">
        <DollarIconLarge /> <span class="ml-4">Add/Remove Orders from Current Invoice</span>
      </span>
      <button class="btn-icon d-flex align-center go-back-button" @click="hideAddOrdersHandler">
        <ArrowBack /> <span class="btn-icon--text">Back to Invoice</span>
      </button>
    </h2>

    <div class="mb-3">
      <Summary :summary="payoutsDashboard" />
    </div>

    <PayoutsTable :payoutsData="filteredPayouts" />
  </aside>
</template>
