<script>
import { DollarIcon } from "@/icons";
import { FETCH_PUSH_SETTINGS } from "@/store/actions.type";
import CommissionDetails from '@/views/payouts/components/CommissionDetails.vue';
import PayoutCheckbox from '@/views/payouts/components/PayoutCheckbox.vue';
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";
import ViewPayoutDialog from "@/views/payouts/components/ViewPayoutDialog.vue";
import Pagination from '@/components/UI/Pagination.vue'

export default {
  name: "PayoutsTable",

  data() {
    return {
      page: 1,
    }
  },

  async created() {
    await this.$store.dispatch(`orders/${FETCH_PUSH_SETTINGS}`, {
      storeId: this.currentShop.id
    });
  },

  mixins: [payoutsMixin],

  props: ["payoutsData"],

  components: {
    CommissionDetails,
    DollarIcon,
    PayoutCheckbox,
    ViewPayoutDialog,
    Pagination,
  },

  methods: {
    async createPayoutHandler(payout) {
      await this.addPayoutToInvoicedOrders(payout);
      this.isPayoutDialogVisible = true;
    },

    updateCurrentPageHandler(page) {
      this.payoutsCurrentPage = page;
      this.fetchPayouts(+this.payableOrdersFilters.target_store, this.currentShop.id, this.payoutsCurrentPage);

    }
  }
}
</script>

<template>
  <section class="table payouts-table">
    <v-simple-table>
      <template>
        <thead>
          <tr>
            <th style="width: 3%; padding-left: 11px;"></th>
            <th style="width: 16%;">Date (AEST)</th>
            <th style="width: 11%;">Age (Days)</th>
            <th style="width: 10%;">Order #</th>
            <th style="width: 15%;">Sales</th>
            <th style="width: 15%;">Commission</th>
            <th style="width: 15%;">Payout Amount</th>
            <th style="width: 15%;" class="hide-in-modal text-right">Actions</th>
          </tr>
        </thead>
        <tbody v-if="payouts.length === 0">
          <tr class="no-hover">
            <td colspan="9" class="text-center">
              Payouts Not Found!
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="payout in payoutsData" :key="payout.order_id" :class="{ 'selected': payoutsDashboard.payoutIds.includes(payout.order_id) }">
            <td style="padding-left: 11px;">
              <PayoutCheckbox :payout="payout" />
            </td>
            <td>{{ payout.date }}</td>
            <td>{{ payout.age }}</td>
            <td>{{ payout.order_number }}</td>
            <td class="tabular-fonts">{{ payout.sales | currencyFormatter(currency) }}</td>
            <td class="text-success tabular-fonts">
              +<CommissionDetails :commission="payout.commission" :record="payout" :currency="currency" :showCommission="false" />
            </td>
            <td class="tabular-fonts"><strong>{{ payout.payout_amount | currencyFormatter(currency) }}</strong></td>
            <td class="hide-in-modal text-right">
              <v-btn elevation="0" class="btn btn-border" @click="createPayoutHandler(payout)" v-if="payout.status === 'unpaid'" :disabled="loadindDashboardStats">
                <DollarIcon /> <span class="ml-2">Create Payout</span>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <Pagination :pagination="payoutsPagination" @updateCurrentPage="updateCurrentPageHandler" class="v2 bt-0" />

    <!-- View Payout Dialog -->
    <ViewPayoutDialog v-if="isViewPayoutDialogVisible" />
  </section>
</template>
