<script>
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";

export default {
  name: "PayoutCheckbox",

  mixins: [payoutsMixin],

  data() {
    return {
      isChecked: false,
    }
  },

  mounted() {
    this.isChecked = this.isPayoutSelected();
  },

  watch: {
    'payoutsDashboard.payoutIds'() {
      this.isChecked = this.isPayoutSelected();
    },
  },

  props: {
    payout: {
      required: true,
      type: Object
    }
  },

  methods: {
    isPayoutSelected() {
      const { order_id } = this.payout;
      return this.payoutsDashboard.payoutIds.includes(order_id);
    },
  }
}
</script>

<template>
  <v-checkbox
    :disabled="isAddingToInvoicedOrdersList"
    @change="selectPayoutsFromCheckboxHandler($event, payout)"
    dense
    hide-details
    v-if="payout.status === 'unpaid'"
    v-model="isChecked" />
</template>
